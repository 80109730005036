import React, { useEffect } from "react";
import {useParams} from "react-router-dom";
import "./Home.scss";
import Navbar from "../../components/Navbar";
import WelcomeSec from "../../components/WelcomeSec";
import ServicesSec from "../../components/ServicesSec";
// import Testimonials from "../../components/Testimonials";
import EventsSec from "../../components/EventsSec";
import MembershipSec from "../../components/MembershipSec";
// import TeamSec from "../../components/TeamSec";
import DonationSec from "../../components/DonationSec";
import ContactSec from "../../components/ContactSec";
import Footer from "../../components/Footer";
import PartySec from "../../components/PartySec";
// import TicketModal from "../../components/TicketModal";
// import FloatingIcon from "../../assets/images/floating-icon.png";
const Home = () => {
  // const [ticketModalOpen, setTicketModalOpen] = useState(false);
  const {sec}=useParams()

  // Parse the search string for query parameters

  // Get specific query parameters
  const sectionId = sec;

  const SECTIONS_IDS = [
    "about",
    "services",
    "classes",
    "membership",
    "party",
    "donation",
    "contact",
    // "team",
  ];

  useEffect(() => {
    if (sectionId&&SECTIONS_IDS.find((id) => id === sectionId)) {
      setTimeout(
        () =>
          document
            ?.getElementById(sectionId)
            .scrollIntoView({ behavior: "smooth" }),
        900
      );
    }
  }, [sectionId]);

  return (
    <>
      {/* Ticket Selling Modal  */}
      {/* <TicketModal isOpen={ticketModalOpen} setIsOpen={setTicketModalOpen} /> */}
      <div className="home">
        {/* <div
          className="home_menuButton"
          onClick={() => setTicketModalOpen(true)}
        >
          <img src={FloatingIcon} alt="floating_icon" />
        </div> */}
        <Navbar />
        <WelcomeSec />
        <ServicesSec />
        {/* <Testimonials /> */}
        <EventsSec />
        <MembershipSec />
        {/* <TeamSec /> */}
        <PartySec />
        <DonationSec />
        <ContactSec />
        <Footer />
      </div>
    </>
  );
};

export default Home;
