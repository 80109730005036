import React, { forwardRef } from "react";
import "./Dropdown.scss";

const Dropdown = forwardRef(({ id, label, options = [], ...rest }, ref) => {
  return (
    <div className="dropdown">
      {label && <label htmlFor={id}>{label}</label>}
      <select ref={ref} {...rest}>
        {options.map((d, i) => (
          <option key={i} value={d.value}>
            {d.label}
          </option>
        ))}
      </select>
    </div>
  );
});

export default Dropdown;
