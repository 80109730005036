import React from "react";
import "./Toggler.scss";

const Toggler = ({ checked = false, id = "", setCheck }) => {
  return (
    <div className="toggler" >
      <input type="checkbox" checked={checked} id={id} />
      <label for={id} onClick={() => setCheck(!checked)}></label>
    </div>
  );
};

export default Toggler;
