import React from "react";
import "./SummerCamp.scss";
import Logo from "../../assets/images/logo.svg";
import SummerCampQR from "../../assets/images/summerCampQR.png";
import { Link, useNavigate } from "react-router-dom";
import Button from "../../components/Button";

const SummerCamp = () => {
  const navigate = useNavigate();

  return (
    <div className="summerCamp">
      <div className="container">
        <div className="summerCamp_logo">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="icon icon-tabler icon-tabler-arrow-left"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="#000000"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
            onClick={() => navigate("/")}
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M5 12l14 0" />
            <path d="M5 12l6 6" />
            <path d="M5 12l6 -6" />
          </svg>
          <img onClick={() => navigate("/")} src={Logo} alt="creator-zone" />
        </div>
        <div className="summerCamp_heading">
          <h3>Camps & Programs</h3>
        </div>
        <div className="summerCamp_topText">
          <p>FALL INTO STEAM ‘24</p>
          <p>
            This fall, Creator Zone offers K-12th grade afternoon programs
            designed to expand interests and skills in STEAM. Students will have
            the opportunity to explore their passions, develop their own
            concepts, and acquire new skills through a self-directed learning
            approach in our unique Makerspace setting!
          </p>
          <p>
            With the support of an instructor, Creator Zone will provide the
            necessary equipment, materials, and guidance to help participants
            bring their imaginative ideas to life! Start dates, age & skill
            levels and cost specified below for each course.
          </p>
        </div>

        <div className="summerCamp_paras">
          <div>
            <h4>Elementary Art</h4>
            <p>4:30pm-5:30pm Every Wednesday, Sep. 18, - Oct. 23</p>
            <p>
              Dive into the world of art history with a hands-on approach in our
              fun art class! We'll learn about famous artists like Warhol,
              Stuart, Da Vinci, and modern masters like Koons and Yayoi Kusama.
              Each week, we'll try out the unique ways these artists created
              their art and use their techniques to make our own original
              pieces. This class encourages creativity and trying new things,
              helping students develop their own artistic style while learning
              from the greats.
            </p>
            <p>Age: Elementary (Skill level: 5 - 11 years old).</p>
            <p>6 Week Course Cost: $140 total</p>
            <p>
              Reserve your place for{" "}
              <a href="https://app.ticketjump.com/public/creator-zone/event/6e82c14b-5edc-42d2-b8b2-9b396ad729c8/booking">
                Elementary Art here
              </a>{" "}
            </p>
          </div>
          <div>
            <h4>Wearable Art</h4>
            <p>4:30pm-5:30pm Every Thursday, Sep. 19, - Oct. 24</p>

            <p>
              This hands-on course will guide you through the essentials of
              fashion design, pattern cutting, and sewing basics, providing the
              opportunity to develop the skills and confidence to bring your
              unique designs to life. We will utilize machines in the space;
              sewing, 3D printer and laser cutter to complete the 'look'.
              Whether you're an aspiring designer or simply looking to enhance
              your sewing skills, this class will inspire and empower you to
              express your creativity through fashion!
            </p>
            <p>Age: 9-16 years old</p>
            <p> 6 Week Course Cost: $149 total + $25 for final materials</p>
            <p>
              Reserve your place for{" "}
              <a href="https://app.ticketjump.com/public/creator-zone/event/f88d1caf-c9b0-4b41-82ad-b59edc516ff6/booking">
                Wearable Art here{" "}
              </a>{" "}
            </p>
          </div>
          <div>
            <h4>Cosplay</h4>
            <p>[WAITLIST] 4:30pm-5:30pm Every Friday, Sep. 20, - Oct. 25</p>
            <p>
              Unleash your creativity in our Cosplay Costumes class! Designed
              for aspiring cosplayers, this course covers the basics of costume
              design and construction. Students will learn about character
              design, material selection, and essential sewing techniques. Each
              week, we'll work on different aspects of creating a cosplay
              costume, from sketching ideas to constructing and detailing
              pieces. Whether you're a beginner or have some experience, this
              class will help you bring your favorite characters to life in a
              fun and supportive environment.
            </p>
            <p> Age: 10 - 16 years old</p>
            <p>6 Week Course Cost: $149 total + $25 for final materials</p>
            <p>
              Reserve your place for{" "}
              <a href="https://app.ticketjump.com/public/creator-zone/event/1669dae8-1838-4800-aa7e-723570387f93/booking">
                Cosplay here
              </a>{" "}
            </p>
          </div>
          <div>
            <h4>Arts & Science</h4>
            <p>4:30pm-5:30pm, Every Monday, Sep. 30 - Nov. 4</p>
            <p>
              Dive into the exciting world of STEAM with our Art & Science
              Exploration class! This hands-on course combines creative crafts
              with scientific principles, encouraging young minds to learn
              through doing. From making volcanoes and slime to building simple
              circuits and wind-powered cars, each week will bring a new project
              that sparks curiosity and creativity.
            </p>
            <p>Age: Elementary School (Skill level: 5 - 10 years old) </p>
            <p>6 Week Course Cost: $120 total</p>
            <p>
              Reserve your place for{" "}
              <a href="https://app.ticketjump.com/public/creator-zone/event/4c7e2511-6c4b-4d0b-b623-32f60d789bcf/booking">
                Art & Science
              </a>{" "}
            </p>
          </div>
          <div>
            <h4>Kids Robotics</h4>
            <p>4:30pm-5:30pm, Every Tuesday, Oct. 1, - Nov 5</p>
            <p>
              Discover the basics of robotics in our engaging Robotics
              Introduction class! Designed for young engineers, this course
              introduces students to simple machines, and fundamental robotics
              concepts. Join us each week to tackle fun STEM challenges, animate
              LEGOs, explore electronics, and develop new skills in a supportive
              environment.{" "}
            </p>
            <p>Age: Elementary (Skill level: 5 - 10 years old)</p>
            <p>6 Week Course Cost: $165 total</p>
            <p>
              Reserve your place for{" "}
              <a href="https://app.ticketjump.com/public/creator-zone/event/bbac408a-c352-42ed-9f9b-c65f64c98086/booking">
                Kids Robotics Here{" "}
              </a>{" "}
            </p>
          </div>
          <div>
            <h4>Custom 3D Print Design Course</h4>
            <p>4pm - 5pm Saturday’s, Sep. 21 - Oct. 12</p>
            <p>
              In this class, you will learn an introduction on how to design
              something new from scratch using the CAD software Fusion360. No
              prior experience will be required in CAD! Over this 4-week course,
              you will learn the basics of parametric design with Fusion360
              including:
            </p>
            <ul>
              <li> Navigating Fusion360's User Interface</li>
              <li> Navigating the 3D space</li>
              <li> Creating sketches</li>
              <li> Creating solids from a sketch</li>
              <li>
                {" "}
                Exporting your designs for 3D printing, laser cutting, or
                milling
              </li>
              <li> Basic assembly design</li>
            </ul>
            <p>
              This class is intended for 11-17 year old beginners, if your child
              is under 15 years old you are encouraged to stay & learn with
              them!
            </p>
            <p>4 Week Course Cost: $120 total</p>
            <p>
              Reserve your place for{" "}
              <a href="https://app.ticketjump.com/public/creator-zone/event/16cb9ab2-a20c-4e69-bb93-cc1a578a3e2a/booking">
                {" "}
                Custom 3D Print Design Course Here
              </a>
            </p>
          </div>
          <div>
            <h4>Learn to Code 6 Week Course: Python for Newbies</h4>
            <p>7pm - 8pm Thursday’s, Oct. 10 - Oct. 14</p>
            <p>
              You can learn to code at any age, with no prior experience needed.
              The programming language Python can help you automate repetitive
              computer tasks, analyze data, and even build a website!
            </p>
            <p>
              This class is for all age beginners, if your child is under 15
              years old you are encouraged to attend & learn with them!
            </p>
            <p>4 Week Course Cost: $120 total</p>
            <p>
              Reserve your place for{" "}
              <a href="https://app.ticketjump.com/public/creator-zone/event/6a1f55d2-8203-4629-ab99-86613dccdb9c/booking">
                {" "}
                Python for Newbies here
              </a>
            </p>
          </div>
          <div>
            <h4>Creator Zone Program Details</h4>
            <ul>
              <li>
                {" "}
                All Existing Creator Zone Members get $30 credit a month towards
                courses!{" "}
              </li>
              <li>Payment required in full to reserve place</li>
              <li>One time $20 registration fee for non-members</li>
              <li>Invoice will be sent after registration</li>
              <li>Payment options available</li>
              <li>
                You will be contacted for any WAITLIST programs if space becomes
                available
              </li>
            </ul>
            <p>
              You can register your child{" "}
              <a href="https://docs.google.com/forms/d/e/1FAIpQLSdZNO12355l2tZaYNZPDPBTjPiz19v_LSLE6AYfczN3RgOkkw/viewform">
                {" "}
                here{" "}
              </a>{" "}
              for Fall STEAM programs. We look forward to providing an enriching
              experience for you & your family!
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SummerCamp;
