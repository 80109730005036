import React, { useState } from "react";
import "./ServicesCarousel.scss";
import Slider from "react-slick/lib/slider";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Image1 from "../../assets/images/ser_carousel1.png";
import Image2 from "../../assets/images/ser_carousel2.png";
import Image3 from "../../assets/images/ser_carousel3.png";
import Image4 from "../../assets/images/ser_carousel4.png";
import Image5 from "../../assets/images/ser_carousel5.JPG";
import Image6 from "../../assets/images/ser_carousel6.JPEG";
import Image7 from "../../assets/images/ser_carousel7.JPG";
import Image8 from "../../assets/images/ser_carousel8.jpg";
import Image9 from "../../assets/images/ser_carousel9.jpg";
import Image10 from "../../assets/images/ser_carousel10.jpg";
import Image11 from "../../assets/images/ser_carousel11.jpg";
import Image12 from "../../assets/images/ser_carousel12.jpg";
import Image13 from "../../assets/images/ser_carousel13.jpg";
import Image14 from "../../assets/images/ser_carousel14.jpg";
import Image15 from "../../assets/images/ser_carousel15.jpg";
import Image16 from "../../assets/images/ser_carousel16.jpg";
import Image17 from "../../assets/images/ser_carousel17.JPEG";
import ImagePopup from "../ImagePopup";

const Images = [
  Image1,
  Image2,
  Image3,
  Image4,
  Image5,
  Image6,
  Image7,
  Image8,
  Image9,
  Image10,
  Image11,
  Image12,
  Image13,
  Image14,
  Image15,
  Image16,
  Image17,
];

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={`${className} `} style={{ ...style }} onClick={onClick}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.5 12L19.5 12M19.5 12L12.75 5.25M19.5 12L12.75 18.75"
          stroke="#F06575"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={`${className} `} style={{ ...style }} onClick={onClick}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19.5 12L4.5 12M4.5 12L11.25 18.75M4.5 12L11.25 5.25"
          stroke="#667070"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  );
}

const ServicesCarousel = () => {
  const [activeImage, setActiveImage] = useState(null);
  const [openImagePopup, setOpenImagePopup] = useState(false);

  const settings = {
    className: "slider variable-width",
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    arrows: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  const handleImageClick = (image) => {
    setActiveImage(image);
    setOpenImagePopup(true);
  };

  return (
    <>
      <ImagePopup
        image={activeImage}
        isOpen={openImagePopup}
        setIsOpen={setOpenImagePopup}
      />
      <div className="servicesCarousel">
        {" "}
        <Slider {...settings}>
          {Images.map((img, i) => (
            <div className="servicesCarousel_item">
              <img
                onClick={() => handleImageClick(img)}
                className="servicesCarousel_image"
                key={i}
                src={img}
                alt={`carousel${i}`}
              />
            </div>
          ))}
        </Slider>
      </div>
    </>
  );
};

export default ServicesCarousel;
