import React from "react";
import "./WelcomeSec.scss";
import WelcomeImage from "../../assets/images/welcome-img-2x.png";
import ScrollDownArrow from "../../assets/images/scroll-down-arrow.svg";
import Blue1Image from "../../assets/images/welcome-blue1.svg";
import YellowImage from "../../assets/images/welcome-yellow.svg";
import PurpleImage from "../../assets/images/welcome-purple.svg";
import WhiteImage1 from "../../assets/images/welcome-white1.svg";
import YellowLines from "../../assets/images/welcome-yellow-lines.svg";
import WhiteImage2 from "../../assets/images/welcome-white2.svg";
import BlueDots from "../../assets/images/welcome-blue-dots.svg";
import Yellow2Image from "../../assets/images/welcome-yellow2.svg";
import Blue2Image from "../../assets/images/welcome-blue2.svg";

const WelcomeSec = () => {
  return (
    <section id="welcomeSection" className="welcomeSec">
      <img src={Blue1Image} alt="blueCircle" className="welcomeSec_blue1" />
      <img src={YellowImage} alt="yellowCircle" className="welcomeSec_yellow" />

      <img src={Yellow2Image} alt="yellow2" className="welcomeSec_yellow2" />
      <img src={Blue2Image} alt="blue2" className="welcomeSec_blue2" />

      <div className="welcomeSec_imagesContainer">
        <img
          src={WhiteImage1}
          alt="whiteCircle"
          className="welcomeSec_white1"
        />
        <img
          src={YellowLines}
          alt="whiteCircle"
          className="welcomeSec_yellowLines"
        />
        <img
          src={PurpleImage}
          alt="purpleCircle"
          className="welcomeSec_purple"
        />
        <img
          src={WhiteImage2}
          alt="whiteCircle"
          className="welcomeSec_whiteImage2"
        />
        <img src={BlueDots} alt="blueDots" className="welcomeSec_blueDots" />
      </div>
      <img src={WelcomeImage} className="welcomeSec_image" alt="welcome" />
      <div className="welcomeSec_content">
        <small>WELCOME TO CREATOR ZONE</small>
        <h1>Ignite Curiosity. Foster Creativity. Empower Innovation.</h1>
        <p>
          Nestled in the heart of Lake Stevens, WA, Creator Zone is not your
          run-of-the-mill makerspace – it's an immersive nonprofit venue where
          self-led discovery takes the lead. Our contemporary space is designed
          to transport you to a realm where exploration, creativity, and
          inspiration effortlessly merge. Come join the adventure! 🚀
        </p>
      </div>
      <img
        id="about"
        className="welcomeSec_scrollArrow"
        src={ScrollDownArrow}
        alt="arrow"
      />
      <div className="welcomeSec_detailsContainer">
        <div className="welcomeSec_details">
          <small>OUR mission</small>

          <h3>Creator Zone’s Mission </h3>
          <p>
            Our mission is to ignite curiosity, foster creativity, and empower
            innovation through immersive STEM and Art experiences, while
            providing access to cutting-edge technology and resources, modern
            training courses, and an environment that enables a vibrant
            community of confident, skilled individuals.
          </p>

          <h4>What is a makerspace? </h4>
          <p>
            A makerspace, also known as a "maker lab" or "hackerspace," is a
            collaborative and community-oriented workspace where individuals
            with shared interests, often in technology, art, engineering, or DIY
            (do-it-yourself) activities, can gather to create, innovate, and
            collaborate on projects. Makerspaces provide access to a wide range
            of tools, equipment, and resources that might be too expensive or
            specialized for individuals to own independently.
          </p>
          {/* <Button white>Become a member</Button> */}
        </div>
        <div className="welcomeSec_details">
          <small>HOW IT WORKS</small>
          <h3>How does it work?</h3>
          <div className="welcomeSec_detailPoint">
            <div className="welcomeSec_detailPointNumber">1</div>
            <div>
              <h4>Discover</h4>
              <p>
                Step inside and explore our diverse array of modern tools and
                equipment. Join us 4-7pm Wednesday evenings for Open House tours
                & sign ups, or{" "}
                <span
                  onClick={() =>
                    document
                      ?.getElementById("membership")
                      .scrollIntoView({ behavior: "smooth" })
                  }
                  style={{ textDecoration: "underline", cursor: "pointer" }}
                >
                  become a member now!
                </span>
              </p>
            </div>
          </div>
          <div className="welcomeSec_detailPoint">
            <div className="welcomeSec_detailPointNumber">2</div>
            <div>
              <h4>Learn</h4>
              <p>
                Once you become a member, attend workshops to learn how to use
                the equipment & develop new skills. Check out our{" "}
                <span
                  onClick={() =>
                    document
                      ?.getElementById("classes")
                      .scrollIntoView({ behavior: "smooth" })
                  }
                  style={{ textDecoration: "underline", cursor: "pointer" }}
                >
                  workshop schedule here
                </span>
              </p>
            </div>
          </div>
          <div className="welcomeSec_detailPoint">
            <div className="welcomeSec_detailPointNumber">3</div>
            <div>
              <h4>Create & Collaborate</h4>
              <p>
                Let your imagination run wild as you turn your ideas into
                reality! Be inspired by the limitless possibilities of
                innovation as you collaborate, learn, and share with others in
                our vibrant, dynamic space.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WelcomeSec;
