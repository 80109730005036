import React from "react";
import "./Button.scss";

const Button = ({
  parentClass = "",
  white = false,
  outline = false,
  children,
  ...rest
}) => {
  return (
    <button
      className={`Button ${white ? "Button_white" : ""} ${
        outline ? "Button_outline" : ""
      } ${parentClass}`}
      {...rest}
    >
      {children}
    </button>
  );
};

export default Button;
