import React from "react";
import AppRoutes from "./routes";
import "./scss/index.scss";

function App() {
  const vh = window.innerHeight * 0.01;
  window.document.documentElement.style.setProperty("--vh", `${vh}px`);

  window.visualViewport.addEventListener("resize", (event) => {
    let vh = (event.target.height + event.target.offsetTop) * 0.01;
    window?.document.documentElement.style.setProperty("--vh", `${vh}px`);
  });
  return <AppRoutes />;
}

export default App;
