import axios from "axios";

export const DASHBOARD_URL =
  process.env.REACT_APP_ENV === "dev"
    ? process.env.REACT_APP_DASHBOARD_URL_DEV
    : process.env.REACT_APP_DASHBOARD_URL_PROD;

export const URL =
  process.env.REACT_APP_ENV === "dev"
    ? process.env.REACT_APP_DEV_BASE_URL
    : process.env.REACT_APP_PROD_BASE_URL;

export const ORGANIZATION_NAME =
  process.env.REACT_APP_ENV === "dev"
    ? process.env.REACT_APP_ORGANIZATION_NAME_DEV
    : process.env.REACT_APP_ORGANIZATION_NAME_PROD;

export const getEventsAPI = async (query) => {
  try {
    const res = await axios.get(
      `${URL}/public/${ORGANIZATION_NAME}/events?${query}`
    );
    return res;
  } catch (err) {
    console.log(err);
  }
};

export const getEventById = async (id) => {
  try {
    const res = await axios.get(
      `${URL}/public/${ORGANIZATION_NAME}/events/${id}`
    );
    return res;
  } catch (err) {
    console.log(err);
  }
};

export const getEventsTypesAPI = async () => {
  try {
    const res = await axios.get(
      `${URL}/public/${ORGANIZATION_NAME}/events/types`
    );
    return res;
  } catch (err) {
    console.log(err);
  }
};

export const postUserInfo = async (data) => {
  try {
    const res = await axios.post("https://formspree.io/f/mrgwabjj", data);
    return res?.data;
  } catch (err) {
    console.log(err);
    throw new Error(err);
  }
};

export const postPartyInfo = async (data) => {
  try {
    const res = await axios.post("https://formspree.io/f/mknllkrg", data);
    return res?.data;
  } catch (err) {
    console.log(err);
    throw new Error(err);
  }
};
