import React from "react";
import "./DonationSec.scss";
import DonateIcon from "../../assets/images/donate-icon.png";
import Button from "../Button";

const DonationSec = () => {
  return (
    <section className="donationSec" id="donation">
      <div className="container">
        <div className="donationSec_content">
          <h3>Support Us Through Donations</h3>
          <p id="contact">
            You have the power to play a vital role in the growth of Creator
            Zone by supporting us through donations. To make a contribution,
            please visit our dedicated donation page and become an integral part
            of our journey to inspire and educate future innovators. We
            sincerely thank you for joining us in this exhilarating adventure of
            exploration, creation, and inspiration!
          </p>
        </div>
        <div className="donationSec_btnContainer">
          <img src={DonateIcon} alt="donate" />
          <a
            href="https://donate.stripe.com/00g17b3DN2b80Q8288"
            target="_blank"
            rel="noreferrer"
          >
            <Button white>Donate now</Button>
          </a>
        </div>
      </div>
    </section>
  );
};

export default DonationSec;
