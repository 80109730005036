import React from "react";
import "./ImagePopup.scss";
import Modal from "../Modal";

const ImagePopup = ({ isOpen, setIsOpen, image }) => {
  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <div className="ImagePopup">
        <div className="ImagePopup_cross">
          <svg
            onClick={() => setIsOpen(false)}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="heroicons-outline/x-mark">
              <path
                id="Vector"
                d="M6 18L18 6M6 6L18 18"
                stroke="#fff"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </g>
          </svg>
        </div>
        <img src={image} alt="pic" className="ImagePopup_picture" />
      </div>
    </Modal>
  );
};

export default ImagePopup;
