import React from "react";
import "./ServicesPopup.scss";
import Modal from "../Modal";
import XMark from "../../assets/images/x-mark.svg";

const ServicesPopup = ({
  isOpen,
  setIsOpen,
  data,
  currentServiceModalIndex,
  setCurrentServiceModalIndex,
}) => {
  const { picture, title, details, popup } = data[currentServiceModalIndex];
  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <div className="servicesPopup">
        <img
          src={XMark}
          alt="cross"
          onClick={() => setIsOpen(false)}
          className="servicesPopup_cross"
        />
        <div className="servicesPopup_main">
          <img src={picture} alt="service" className="servicesPopup_pic" />
          <small>SERVICES</small>
          <h3>{title}</h3>
          <p>{popup ? popup : details}</p>
        </div>
        <footer>
          <div
            className={`servicesPopup_arrow ${
              currentServiceModalIndex === 0 ? "servicesPopup_arrowDisable" : ""
            }`}
            onClick={() =>
              setCurrentServiceModalIndex(currentServiceModalIndex - 1)
            }
          >
            <svg
              width="20"
              height="21"
              viewBox="0 0 20 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="Media / Icon/Unfilled/cheveron-left">
                <path
                  id="Icon"
                  d="M12 15.0537L7 10.0536L12 5.05365"
                  stroke="#171717"
                  stroke-width="1.67"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
            </svg>
            {data[currentServiceModalIndex - 1]?.tag}
          </div>
          <div
            className={`servicesPopup_arrow ${
              currentServiceModalIndex === data.length - 1
                ? "servicesPopup_arrowDisable"
                : ""
            }`}
            onClick={() =>
              setCurrentServiceModalIndex(currentServiceModalIndex + 1)
            }
          >
            {data[currentServiceModalIndex + 1]?.tag}
            <svg
              width="20"
              height="21"
              viewBox="0 0 20 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="Media / Icon/Unfilled/cheveron-right">
                <path
                  id="Icon"
                  d="M7 5.05365L12 10.0536L7 15.0536"
                  stroke="#171717"
                  stroke-width="1.67"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
            </svg>
          </div>
        </footer>
      </div>
    </Modal>
  );
};

export default ServicesPopup;
