import React, { useCallback, useEffect, useRef, useState } from "react";
import "./EventsSec.scss";
import Collage from "../../assets/images/events-collage.png";
import SearchIcon from "../../assets/images/search-icon.svg";
import Button from "../Button";
import LeftArrow from "../../assets/images/left-arrow.svg";
import RightArrow from "../../assets/images/right-arrow.svg";
import EventsImg1 from "../../assets/images/events1.jpeg";
import RightArrowLong from "../../assets/images/long-right-arrow.svg";
import EventsBlue from "../../assets/images/events-blue1.svg";
import EventsWhite from "../../assets/images/events-white.svg";
import EventsBlueDots from "../../assets/images/events-blue-dots.svg";
import EventsPopup from "../EventsPopup";
import {
  getEventById,
  getEventsAPI,
  getEventsTypesAPI,
} from "../../apis/backend_helper";
import Toggler from "../Toggler";
import moment from "moment-timezone";
import { debounce } from "../../utils";
import { useLocation } from "react-router-dom";
// import SoldIcon from "../../assets/images/sold.png";

const EventsFrom = ({
  filterOpen,
  limitPerPage,
  setLimitPerPage,
  setCurrentPage,
  dateFilter,
  setDateFilter,
  setKeywords,
  eventTypes,
  event,
  setEvent,
  eventSubType,
  setEventSubType,
}) => {
  const [search, setSearch] = useState("");

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onSearchChange = useCallback(
    debounce((inputVal) => handleSearch(inputVal), 800),
    []
  );

  const handleSearch = (value) => {
    setKeywords(value);
    setCurrentPage(1);
  };

  const handleClear = () => {
    setKeywords("");
    setSearch("");
    setDateFilter(true);
    setCurrentPage(1);
    setEvent("");
    setEventSubType("");
  };

  return (
    <form
      className={`eventsSec_form ${filterOpen ? "eventsSec_formOpen" : ""}`}
    >
      <NumberPerPage
        limitPerPage={limitPerPage}
        setLimitPerPage={setLimitPerPage}
        setCurrentPage={setCurrentPage}
      />
      <div className="eventsSec_formToggler">
        <span onClick={() => setDateFilter(false)}>Past</span>
        <Toggler
          checked={dateFilter}
          setCheck={setDateFilter}
          id="eventsType"
        />
        <span onClick={() => setDateFilter(true)}>Upcoming</span>
      </div>
      <div className="eventsSec_formDropdownContainer">
        <div className="eventsSec_formDropdown">
          <label htmlFor="eventType">Type</label>
          <select
            value={event}
            onChange={(e) => setEvent(e.target.value)}
            id="eventType"
          >
            <option value={-1}>Select Type</option>
            {eventTypes?.map((d) => (
              <option key={d.id} value={d.id}>
                {d.name}
              </option>
            ))}
          </select>
        </div>
        <div className="eventsSec_formDropdown">
          <label htmlFor="eventSubType">Sub Type</label>
          <select
            value={eventSubType}
            onChange={(e) => setEventSubType(e.target.value)}
            id="eventSubType"
            disabled={event == -1}
          >
            <option>Select Sub-Type</option>
            {eventTypes
              ?.find((t) => t.id == event)
              ?.eventSubTypes.map((d) => (
                <option key={d.id} value={d.id}>
                  {d.name}
                </option>
              ))}
          </select>
        </div>
      </div>

      <div className="eventsSec_search">
        <input
          type="text"
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
            onSearchChange(e.target.value);
          }}
          placeholder="Filter classes based on keywords"
        />
        <img src={SearchIcon} alt="search" />
      </div>
      <div className="eventsSec_formBtns">
        <Button outline type="button" onClick={handleClear}>
          Clear
        </Button>
      </div>
    </form>
  );
};

const EventsArrows = ({
  currentPage = 1,
  totalCount = 1,
  limitPerPage = 4,
  totalPages = 1,
  setCurrentPage,
}) => {
  return (
    <div className="eventsSec_arrows">
      Showing {(currentPage - 1) * limitPerPage + 1}-
      {currentPage === totalPages
        ? totalCount
        : (currentPage - 1) * limitPerPage + limitPerPage}{" "}
      of {totalCount}
      <img
        src={LeftArrow}
        onClick={() => setCurrentPage(currentPage === 1 ? 1 : currentPage - 1)}
        alt="left"
      />
      <img
        src={RightArrow}
        alt="right"
        onClick={() =>
          setCurrentPage(
            currentPage === totalPages ? totalPages : currentPage + 1
          )
        }
      />
    </div>
  );
};

const NumberPerPage = ({ limitPerPage, setLimitPerPage, setCurrentPage }) => {
  return (
    <div className="eventsSec_headerSize">
      Show{" "}
      <select
        value={limitPerPage}
        onChange={(e) => {
          setLimitPerPage(e.target.value);
          setCurrentPage(1);
        }}
      >
        <option value={12}>12</option>
        <option value={24}>24</option>
        <option value={48}>48</option>
        <option value={96}>96</option>
      </select>{" "}
      per page
    </div>
  );
};

const EventsSec = () => {
  const sectionRef = useRef(null);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const eventId = searchParams.get("event");

  const [filterOpen, setFilterOpen] = useState(false);
  const [eventsPopup, setEventsPopup] = useState(false);
  const [currentModalEventIndex, setCurrentModalEventIndex] = useState(-1);
  const [data, setData] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [limitPerPage, setLimitPerPage] = useState(12);
  const [dateFilter, setDateFilter] = useState(true);
  const [keywords, setKeywords] = useState("");
  const [eventType, setEventType] = useState(-1);
  const [eventSubType, setEventSubType] = useState(-1);
  const [eventTypes, setEventTypes] = useState([]);
  const [modalsData, setModalsData] = useState([]);
  const [currentModalPage, setCurrentModalPage] = useState(-1);
  const [activeEvent, setActiveEvent] = useState({});

  useEffect(() => {
    getEventTypes();
  }, []);

  useEffect(() => {
    setEventSubType(-1);
  }, [eventType]);

  useEffect(() => {
    loadEventsData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentPage,
    limitPerPage,
    dateFilter,
    keywords,
    dateFilter,
    eventType,
    eventSubType,
  ]);

  useEffect(() => {
    if (data?.data?.length > 0) {
      sectionRef?.current?.scrollIntoView();
    }
  }, [currentPage]);

  const getEventTypes = async () => {
    try {
      const res = await getEventsTypesAPI();
      setEventTypes(res?.data);
    } catch (err) {
      console.log(err);
    }
  };

  const loadSingleEvent = async () => {
    try {
      const res = await getEventById(eventId);
      sectionRef?.current?.scrollIntoView();
      setActiveEvent(res?.data);
      setEventsPopup(true);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (eventId) {
      loadSingleEvent();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventId]);

  const loadEventsData = async () => {
    try {
      const res = await getEventsAPI(
        `ascending=true&limit=${limitPerPage}&page=${currentPage}&dateFilter=${
          dateFilter ? "upcoming" : "past"
        }${keywords ? `&search=${keywords}` : ""}${
          eventType > 0 ? `&eventTypeId=${eventType}` : ""
        }${eventSubType > 0 ? `&eventSubTypeId=${eventSubType}` : ""}&is`
      );
      setData(res?.data);
    } catch (err) {
      console.log(err);
    }
  };

  const loadEventsDataForPopups = async (page) => {
    try {
      const res = await getEventsAPI(
        `ascending=true&limit=${limitPerPage}&page=${page}&dateFilter=${
          dateFilter ? "upcoming" : "past"
        }${keywords ? `&search=${keywords}` : ""}${
          eventType > 0 ? `&eventTypeId=${eventType}` : ""
        }${eventSubType > 0 ? `&eventSubTypeId=${eventSubType}` : ""}`
      );
      return res?.data?.data;
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    async function handleModalsData() {
      // current modal page is less than
      if (
        currentModalEventIndex > modalsData?.length - 3 &&
        currentModalPage < data?.pagination?.totalPages
      ) {
        const data = await loadEventsDataForPopups(currentModalPage + 1);
        setModalsData([...modalsData, ...data]);
        setCurrentModalPage(currentModalPage + 1);
      }

      // current modal page is greater than 1
      if (currentModalEventIndex < 2 && currentModalPage > 1) {
        const data = await loadEventsDataForPopups(currentModalPage - 1);
        setModalsData([...data, ...modalsData]);
        setCurrentModalPage(currentModalPage - 1);
        setCurrentModalEventIndex(currentModalEventIndex + limitPerPage);
      }
    }
    handleModalsData();
  }, [currentModalEventIndex]);

  return (
    <>
      <EventsPopup
        isOpen={eventsPopup}
        setIsOpen={setEventsPopup}
        currentModalEventIndex={currentModalEventIndex}
        setCurrentModalEventIndex={setCurrentModalEventIndex}
        event={
          eventId && currentModalEventIndex === -1
            ? activeEvent
            : currentModalEventIndex > -1
            ? modalsData?.[currentModalEventIndex]
            : {}
        }
        totalEvents={modalsData?.length}
      />
      <section className="eventsSec">
        <div className="idBox" id="classes" />
        <img src={EventsBlue} alt="blue" className="eventsSec_blue" />
        <img src={EventsWhite} alt="white" className="eventsSec_white" />
        <img src={EventsBlueDots} alt="Dots" className="eventsSec_blueDots" />

        <img src={Collage} className="eventsSec_collage" alt="collage" />
        <small>workshops & classes</small>
        <h3 className="eventsSec_headingPadding">Workshops & Classes</h3>
        {/* <p className="eventsSec_subText" ref={sectionRef}>
          Registration is required for all events. Please click "Reserve Now" to
          confirm your space in a workshop or camp. Thanks so much - we can't
          wait to welcome you!
        </p> */}
        <div className="eventsSec_formHeader">
          <div
            className={`eventsSec_formHeaderFilter ${
              filterOpen ? "eventsSec_formHeaderFilterOpen" : ""
            }`}
            onClick={() => setFilterOpen(!filterOpen)}
          >
            Filter
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M3 3.99988C3 3.44759 3.44772 2.99988 4 2.99988H16C16.5523 2.99988 17 3.44759 17 3.99988V5.69678C17 5.96199 16.8946 6.21635 16.7071 6.40388L11.8484 11.2625C11.6609 11.4501 11.5556 11.7044 11.5556 11.9696V13.8888L8.44444 16.9999V11.9696C8.44444 11.7044 8.33909 11.4501 8.15155 11.2625L3.29289 6.40388C3.10536 6.21635 3 5.96199 3 5.69678V3.99988Z"
                stroke="#334041"
                stroke-width="1.67"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <EventsArrows
            currentPage={data?.pagination?.page}
            totalCount={data?.pagination?.totalCount}
            limitPerPage={data?.pagination?.limit}
            totalPages={data?.pagination?.totalPages}
            setCurrentPage={setCurrentPage}
          />
        </div>
        <EventsFrom
          limitPerPage={limitPerPage}
          setLimitPerPage={setLimitPerPage}
          setCurrentPage={setCurrentPage}
          filterOpen={filterOpen}
          dateFilter={dateFilter}
          setDateFilter={setDateFilter}
          setKeywords={setKeywords}
          eventTypes={eventTypes}
          event={eventType}
          setEvent={setEventType}
          eventSubType={eventSubType}
          setEventSubType={setEventSubType}
        />
        <div className="eventsSec_header">
          <NumberPerPage
            limitPerPage={limitPerPage}
            setLimitPerPage={setLimitPerPage}
            setCurrentPage={setCurrentPage}
          />
          <EventsArrows
            currentPage={data?.pagination?.page}
            totalCount={data?.pagination?.totalCount}
            limitPerPage={data?.pagination?.limit}
            totalPages={data?.pagination?.totalPages}
            setCurrentPage={setCurrentPage}
          />
        </div>
        <div className="eventsSec_main">
          {data?.data?.length > 0 ? (
            data?.data?.map(
              (d, i) =>
                d?.images?.[0]?.imageUrl &&
                d?.isActive && (
                  <div
                    key={i}
                    onClick={() => {
                      setCurrentModalEventIndex(i);
                      setEventsPopup(true);
                      setModalsData([...data?.data]);
                      setCurrentModalPage(currentPage);
                    }}
                    className="eventsSec_eventBox"
                  >
                    {d?.maxAttendees &&
                      (d?.maxAttendees === d?.attendeesCount?.[0]?.count ||
                        d?.maxAttendees < d?.attendeesCount?.[0]?.count) && (
                        <span className="eventsSec_eventBoxSold">Sold Out</span>
                      )}
                    <div>
                      <img
                        src={d?.images?.[0]?.imageUrl || EventsImg1}
                        className="eventsSec_eventBoxPic"
                        alt="event"
                      />
                      <span>
                        {moment
                          .tz(d?.startDateTime, d?.timezone)
                          .clone()
                          .tz(moment.tz.guess())
                          .format("ddd DD MMM, h:mma")}
                        -
                        {moment
                          .tz(d?.endDateTime, d?.timezone)
                          .clone()
                          .tz(moment.tz.guess())
                          .format("h:mma")}
                      </span>
                      <h3>{d?.title}</h3>
                      <p>
                        {d?.isMemberOnly ? (
                          <b>Members Only Event</b>
                        ) : (
                          `Non-Member Price: ${
                            d?.isFree|| d?.tickets?.[0]?.nonMemberPrice===null||
                            d?.tickets?.[0]?.nonMemberPrice===0||d?.tickets?.[0]?.nonMemberPrice==="0.00"
                              ? "Free"
                              : `$${(
                                  d?.tickets?.[0]?.nonMemberPrice / 100
                                )?.toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}`
                          }`
                        )}
                      </p>
                      <p>
                        Member Price:{" "}
                        {d?.isFree ||
                         d?.tickets?.[0]?.memberPrice===null||
                         d?.tickets?.[0]?.memberPrice===0||d?.tickets?.[0]?.memberPrice==="0.00"
                          ? "Free"
                          : `$${(
                              d?.tickets?.[0]?.memberPrice / 100
                            )?.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}`}
                      </p>
                      <p>
                        Max Attendees:{" "}
                        {d?.maxAttendees ? `${d?.maxAttendees}` : "No limit"}
                      </p>
                    </div>
                    <img
                      src={RightArrowLong}
                      className="eventsSec_eventBoxArrow"
                      alt="arrow"
                    />
                  </div>
                )
            )
          ) : (
            <h4>No Data Found.</h4>
          )}
        </div>
        {data?.pagination?.totalPages > 1 && (
          <div className="eventsSec_pagination">
            <img
              onClick={() =>
                setCurrentPage(currentPage === 1 ? 1 : currentPage - 1)
              }
              src={LeftArrow}
              alt="left"
            />
            {[
              ...[...Array(data?.pagination?.totalPages).keys()].map(
                (i) => i + 1
              ),
            ].map((d) => (
              <span
                onClick={() => setCurrentPage(d)}
                className={
                  d === currentPage ? "eventsSec_paginationActive" : ""
                }
              >
                {d}
              </span>
            ))}
            <img
              src={RightArrow}
              onClick={() =>
                setCurrentPage(
                  currentPage === data?.pagination?.totalPages
                    ? data?.pagination?.totalPages
                    : currentPage + 1
                )
              }
              alt="right"
            />
          </div>
        )}
      </section>
    </>
  );
};

export default EventsSec;
