import React, { forwardRef } from "react";
import "./DatePicker.scss";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CalendarIcon from "../../assets/images/input-calendar.svg";

const DatePicker = forwardRef(({ id, label, ...rest }, ref) => {
  return (
    <div className="datePicker">
      {label && <label htmlFor={id}>{label}</label>}
      <img
        src={CalendarIcon}
        alt="icon"
        className="datePicker_icon"
        onClick={() =>
          document
            .querySelector(".datePicker")
            .getElementsByTagName("input")?.[0]
            .focus()
        }
      />
      <ReactDatePicker ref={ref} {...rest} />
    </div>
  );
});

export default DatePicker;
