import React, { useState } from "react";
import "./MembershipSec.scss";
import Button from "../Button";
import Slider from "react-slick/lib/slider";
import White1 from "../../assets/images/memebership_white1.svg";
import Pink1 from "../../assets/images/membership_pink1.svg";
import Circles from "../../assets/images/membership_circles.svg";
import Path from "../../assets/images/membership_path.svg";
import Dots from "../../assets/images/membership_dots.svg";
import YellowCircle from "../../assets/images/membership_yellow.svg";
import PurchasePopup from "../PurchasePopup";

const MembershipCard = ({
  price = "0",
  title = "",
  features = [],
  isMonthly = false,
  handlePurchase = () => {},
  link = "",
}) => {
  return (
    <div
      className={`membershipCard ${isMonthly ? "membershipCardMonthly" : ""}`}
    >
      <div className="membershipCard_header">
        <div className="membershipCard_headerMain">
          <div className="membershipCard_titleWrapper">
            <h3>${price}</h3>
            <div className="membershipCard_titleSubTitle">per month</div>
          </div>
          <h4>{title}</h4>
        </div>
      </div>
      <div className="membershipCard_features">
        {features.map((f, i) => (
          <p key={i}>{f}</p>
        ))}
        {/* <a href={link} target="_blank" rel="noreferrer"> */}
        <Button onClick={handlePurchase} parentClass="membershipCard_button">
          Subscribe
        </Button>
        {/* </a> */}
      </div>
    </div>
  );
};

const MobileCarousel = ({ setPurchasePopupOpen }) => {
  const settings = {
    className: "slider",
    dots: false,
    infinite: false,
    slidesToShow: 1.8,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,

    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1.8,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.3,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="mobileMembershipCarousel">
      <Slider {...settings}>
        <MembershipCard
          price="30"
          title="Child Membership"
          isMonthly
          features={[
            "Under 18 years old",
            "Access to all maker labs",
            "Basic materials included",
            "Complementary classes",
          ]}
          handlePurchase={() => setPurchasePopupOpen(true)}
          link="https://buy.stripe.com/cN22bf2zJ3fc6as00g"
        />
        <MembershipCard
          price="70"
          title="Adult Membership"
          features={[
            "Over 18 years old",
            "Access to all maker labs",
            "Basic materials included",
            "Complementary classes",
          ]}
          handlePurchase={() => setPurchasePopupOpen(true)}
          link="https://buy.stripe.com/fZe0374HR5nkcyQaET"
        />
      </Slider>
    </div>
  );
};

const MembershipSec = () => {
  const [purchasePopupOpen, setPurchasePopupOpen] = useState(false);
  return (
    <>
      <PurchasePopup
        isOpen={purchasePopupOpen}
        setIsOpen={setPurchasePopupOpen}
      />
      <section id="membership" className="membershipSec">
        <div className="membershipSec_images">
          <img src={White1} alt="white" className="membershipSec_white" />
          <img src={Pink1} alt="pink" className="membershipSec_pink" />
          <img src={Circles} alt="circles" className="membershipSec_circles" />
          <img src={Dots} alt="dots" className="membershipSec_dots" />
          <img
            src={YellowCircle}
            alt="yellow"
            className="membershipSec_yellow"
          />
          <img src={Path} alt="path" className="membershipSec_path" />
        </div>
        <div className="container">
          <div className="membershipSec_content">
            <small>Memberships</small>
            <h3>Become a member today!</h3>
            <p>
              Becoming a member of Creator Zone means joining a vibrant
              community of learners and creators. As a member, you'll enjoy
              access to our immersive STEM and Arts experiences, equipment and
              engaging workshops. Creator Zone is a place where curiosity is
              nurtured, creativity is encouraged, and innovation is within
              reach, regardless of age or background. Whether you're an eager
              explorer, an aspiring artist, or someone searching for
              inspiration, Creator Zone open the door to a world where learning
              and growth thrive in a welcoming and supportive atmosphere.
            </p>
            <Button
              onClick={() => setPurchasePopupOpen(true)}
              parentClass="membershipSec_contentButton"
            >
              Inquire
            </Button>
          </div>
          {/* <div className="membershipSec_cards">
            <MembershipCard
              price="30"
              title="Child Membership"
              isMonthly
              features={[
                "Under 18 years old",
                "Access to all maker labs",
                "Basic materials included",
                "Complementary classes",
              ]}
              handlePurchase={() => setPurchasePopupOpen(true)}
              link="https://buy.stripe.com/cN22bf2zJ3fc6as00g"
            />
            <MembershipCard
              price="70"
              title="Adult Membership"
              features={[
                "Over 18 years old",
                "Access to all maker labs",
                "Basic materials included",
                "Complementary classes",
              ]}
              handlePurchase={() => setPurchasePopupOpen(true)}
              link="https://buy.stripe.com/fZe0374HR5nkcyQaET"
            />
          </div> */}
          {/* <MobileCarousel setPurchasePopupOpen={setPurchasePopupOpen} /> */}
        </div>
      </section>
    </>
  );
};

export default MembershipSec;
