import React, { useRef } from "react";
import "./EventsPopup.scss";
import Modal from "../Modal";
import XMark from "../../assets/images/x-mark.svg";
import Slider from "react-slick/lib/slider";
import moment from "moment-timezone";
// import SoldIcon from "../../assets/images/sold.png";
import Button from "../Button";
import { DASHBOARD_URL, ORGANIZATION_NAME } from "../../apis/backend_helper";

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={`${className} `} style={{ ...style }} onClick={onClick}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.5 12L19.5 12M19.5 12L12.75 5.25M19.5 12L12.75 18.75"
          stroke="#F06575"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={`${className} `} style={{ ...style }} onClick={onClick}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19.5 12L4.5 12M4.5 12L11.25 18.75M4.5 12L11.25 5.25"
          stroke="#667070"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  );
}

const settings = {
  className: "slider",
  dots: false,
  infinite: false,
  slidesToShow: 2.2,
  slidesToScroll: 2,
  initialSlide: 2,
  arrows: true,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,

  responsive: [
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1.7,
        slidesToScroll: 1,
        initialSlide: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1.3,
        slidesToScroll: 1,
      },
    },
  ],
};

const EventsPopup = ({
  isOpen,
  setIsOpen,
  currentModalEventIndex,
  setCurrentModalEventIndex,
  totalEvents,
  event = {},
}) => {
  const carouselRef = useRef();

  const { id, title, description, images, timezone, instructor, prerequisite } =
    event;

  const isSoldOut =
    event?.maxAttendees &&
    (event?.maxAttendees === event?.attendeesCount?.[0]?.count ||
      event?.maxAttendees < event?.attendeesCount?.[0]?.count);

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <div className="eventsPopup">
        <div>
          <div className="eventsPopup_top">
            <span className="eventsPopup_time">
              {" "}
              {moment
                .tz(
                  event?.startDateTime || event?.dateTimes?.[0]?.startDateTime,
                  timezone
                )
                .clone()
                .tz(moment.tz.guess())
                .format("ddd DD MMM, hh:mm a")}{" "}
              -
              {moment
                .tz(
                  event?.endDateTime || event?.dateTimes?.[0]?.startDateTime,
                  timezone
                )
                .clone()
                .tz(moment.tz.guess())
                .format(" hh:mm a")}
            </span>
            <img
              src={XMark}
              alt="cross"
              onClick={() => setIsOpen(false)}
              className="eventsPopup_cross"
            />
          </div>
          <h3>{title}</h3>
          <p>{description}</p>
          <div className="eventsPopup_subDetailsContainer">
            <div>
              <div className="eventsPopup_subDetails">
                <h4>Instructor: </h4> <p> {instructor ? instructor : "None"}</p>
              </div>
              <div className="eventsPopup_subDetails">
                <h4>Prerequisites: </h4>{" "}
                <p> {prerequisite ? prerequisite : "None"}</p>
              </div>
              <div className="eventsPopup_subDetails">
                <h4>Max Attendees: </h4>{" "}
                <p>
                  {" "}
                  {event?.maxAttendees || event?.dateTimes?.[0]?.maxAttendees
                    ? `${
                        event?.maxAttendees ||
                        event?.dateTimes?.[0]?.maxAttendees
                      } People`
                    : "No Limit"}
                </p>
                {(isSoldOut || event?.dateTimes?.[0]?.isSoldOut) && (
                  <span className="eventsPopup_soldIcon">Sold Out</span>
                )}
              </div>
            </div>
            <div>
              <div className="eventsPopup_subDetails">
                <h4>
                  {event?.isMemberOnly || event?.dateTimes?.[0]?.isMemberOnly
                    ? "Members Only Event"
                    : "Non-Member Price:"}
                </h4>
                {!(
                  event?.isMemberOnly || event?.dateTimes?.[0]?.isMemberOnly
                ) && (
                  <span>
                    {event?.isFree ||
                    event?.dateTimes?.[0]?.isFree ||
                    event?.tickets?.[0]?.nonMemberPrice === null ||
                    event?.tickets?.[0]?.nonMemberPrice === 0 ||
                    event?.tickets?.[0]?.nonMemberPrice === "0.00"
                      ? "Free"
                      : `$${(
                          (event?.tickets?.[0]?.nonMemberPrice ||
                            event?.dateTimes?.[0]?.tickets?.[0]
                              ?.nonMemberPrice) / 100
                        )?.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}`}{" "}
                  </span>
                )}
              </div>
              <div className="eventsPopup_subDetails">
                <h4>Member Price:</h4>
                <span>
                  {event?.isFree ||
                  event?.dateTimes?.[0]?.isFree ||
                  event?.tickets?.[0]?.memberPrice === null ||
                  event?.tickets?.[0]?.memberPrice === 0 ||
                  event?.tickets?.[0]?.memberPrice === "0.00"
                    ? "Free"
                    : `$${(
                        (event?.tickets?.[0]?.memberPrice ||
                          event?.dateTimes?.[0]?.tickets?.[0]?.memberPrice) /
                        100
                      )?.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}`}
                </span>
              </div>
            </div>
          </div>
          {!(isSoldOut || event?.dateTimes?.[0]?.isSoldOut) && (
            <a
              href={`${DASHBOARD_URL}/public/${ORGANIZATION_NAME}/event/${id}/booking`}
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              <Button parentClass="eventsPopup_btn">Reserve</Button>
            </a>
          )}
        </div>
        <div>
          {images?.length > 0 && (
            <div className="eventsPopup_carousel">
              <Slider ref={carouselRef} {...settings}>
                {images.map((d, i) => (
                  <img
                    src={d?.imageUrl}
                    className="eventsPopup_carouselImage"
                    alt="event"
                  />
                ))}
              </Slider>
            </div>
          )}
          {currentModalEventIndex > -1 && (
            <footer>
              <div
                className={`eventsPopup_arrow ${
                  currentModalEventIndex === 0 ? "eventsPopup_arrowDisable" : ""
                }`}
                onClick={() =>
                  setCurrentModalEventIndex((prev) =>
                    prev === 0 ? 0 : prev - 1
                  )
                }
              >
                <svg
                  width="20"
                  height="21"
                  viewBox="0 0 20 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="Media / Icon/Unfilled/cheveron-left">
                    <path
                      id="Icon"
                      d="M12 15.0537L7 10.0536L12 5.05365"
                      stroke="#171717"
                      stroke-width="1.67"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </g>
                </svg>
                Previous event
              </div>
              <div
                className={`eventsPopup_arrow ${
                  currentModalEventIndex === totalEvents - 1
                    ? "eventsPopup_arrowDisable"
                    : ""
                }`}
                onClick={() =>
                  setCurrentModalEventIndex((prev) =>
                    prev === totalEvents - 1 ? totalEvents - 1 : prev + 1
                  )
                }
              >
                Next event
                <svg
                  width="20"
                  height="21"
                  viewBox="0 0 20 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="Media / Icon/Unfilled/cheveron-right">
                    <path
                      id="Icon"
                      d="M7 5.05365L12 10.0536L7 15.0536"
                      stroke="#171717"
                      stroke-width="1.67"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </g>
                </svg>
              </div>
            </footer>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default EventsPopup;
