import React, { forwardRef, useRef, useState } from "react";
import "./ServicesSec.scss";
import ServicesCarousel from "../ServicesCarousel";
import Service3Image from "../../assets/images/service3.png";
// import Service7Image from "../../assets/images/service7.png";
import PaintingAndDrawing from "../../assets/images/Painting&Drawing.png";
import LaserEngraving from "../../assets/images/LaserEngraving.png";
import LongRightArrow from "../../assets/images/long-right-arrow.svg";
import VirtualReality from "../../assets/images/virtual-reality.png";
import ScreenPrinting from "../../assets/images/screen-printing.png";
import ThreeDPrinting from "../../assets/images/3D-Printing.png"
import Circuit from "../../assets/images/Cricut.png"
import Podcast from "../../assets/images/podcast.png";
import Photography from "../../assets/images/photography.png";
import ComputerLab from "../../assets/images/computer-lab.png";
import CollabSpace from "../../assets/images/collab-space.png";
import WoodWorking from "../../assets/images/Woodworking.png";
import Button from "../Button";
import Slider from "react-slick/lib/slider";
import ServiceBlue1 from "../../assets/images/service_blue.svg";
import ServiceBlue2 from "../../assets/images/service_blue2.svg";
import ServiceBlue3 from "../../assets/images/service_blue3.svg";
import ServiceBlueLine from "../../assets/images/service_blueLine.svg";
import ServicesPopup from "../ServicesPopup";
import { postUserInfo } from "../../apis/backend_helper";

const ServicesData = [
  {
    picture: ThreeDPrinting, // Service7Image,
    title: "3D printing",
    tag: "3D printing",
    details:
      "Explore a fleet of six cutting-edge 3D printers that can transform digital designs into physical reality . Basic filament included in all memberships to get you started, along with free workshops to teach you how to use the machines!",
    popup: (
      <>
        At Creator Zone, we proudly present a fleet of six cutting-edge 3D
        printers that possess the incredible ability to turn digital designs
        into tangible reality. As a member, you'll enjoy the added perk of basic
        filament included with every membership, giving you a seamless start on
        your 3D printing journey. What's more, we offer complimentary workshops
        designed to guide you through the intricacies of these machines,
        ensuring that you can unleash their full potential with confidence and
        skill. Come and explore the fascinating world of 3D printing with us!
        🖨️🌟🔧🛠️🚀
      </>
    ),
  },
  {
    picture: Photography,
    title: "Photography",
    tag: "Photography",
    details:
      "Step into our Photography Studio, where your photography aspirations come to life. Our studio is equipped with top-notch gear, featuring professional-grade cameras, precision lighting setups, and a versatile array of backdrops and photography boxes. With these tools at your disposal, you can take your photography game to the next level, capturing moments with clarity, creativity, and style.",
    popup: (
      <>
        What we offer:
        <ul>
          <li>
            Professional Cameras: Access high-quality cameras that are favored
            by seasoned photographers, allowing you to capture every detail with
            precision.
          </li>
          <li>
            Expert Lighting: Illuminate your subjects with expertly designed
            lighting setups that ensure your photos are beautifully lit and
            visually stunning.
          </li>
          <li>
            Versatile Backdrops: Choose from selection of backdrops to set the
            perfect scene for your photos, creating the ambiance and mood you
            desire.
          </li>
          <li>
            Photography Boxes: For product and macro photography, our
            photography boxes provide controlled lighting and composition,
            resulting in captivating images.
          </li>
        </ul>
        Whether you're an amateur or a pro, our Photography Studio offers the
        ideal environment to explore your creativity and develop your
        photography skills.
      </>
    ),
  },
  {
    picture: VirtualReality,
    title: "Virtual reality",
    tag: "Virtual reality",
    details:
      "Embark on a thrilling journey into the metaverse at our VR Lab. Here, you'll discover a world of immersive experiences as you step into virtual reality using top-of-the-line headsets. Learn to create your own VR environments and explore the limitless possibilities of this cutting-edge tech.",
    popup: (
      <>
        What Awaits You:
        <ul>
          <li>
            Immersive Exploration: Dive into immersive experiences that
            transport you to otherworldly realms, all through the power of
            virtual reality.
          </li>
          <li>
            Create Your Own Worlds: Learn the art of crafting your very own VR
            environments, where you become the architect of unique digital
            landscapes.
          </li>
          <li>
            Cutting-Edge Technology: Explore the latest advancements in VR
            technology, pushing the boundaries of what's possible in the digital
            realm.
          </li>
          <li>
            Endless Adventure: With virtual reality, the adventure is boundless,
            limited only by your imagination and creativity.
          </li>
        </ul>
      </>
    ),
  },
  {
    picture: Service3Image,
    title: "Robotics / Electronics",
    tag: "Robotics / Electronics",
    details:
      "Unleash your inner engineer and tinker with robotics and electronics. Build interactive robots, experiment with circuitry, and create innovative machines using our engineering tools. We have quickstart STEM kits available for beginners to advanced tinkerers.",
    popup: (
      <>
        What we offer:
        <ul>
          <li>
            <strong>Circuitry Exploration: </strong> Delve into the fascinating
            realm of circuitry, where you can design and experiment with
            electronic connections, unlocking endless possibilities.
          </li>
          <li>
            <strong> STEM Kits: </strong>
            We offer quickstart STEM kits suitable for everyone, from beginners
            taking their first steps into this exciting world to advanced
            tinkerers seeking new challenges.
          </li>
          <li>
            <strong> Innovative Machines & robots: </strong>
            Create cutting-edge machines & robots that showcase your ingenuity
            and technical prowess, pushing the boundaries of innovation.
          </li>
        </ul>
      </>
    ),
  },

  {
    picture: ComputerLab,
    title: "Computers & Design Software",
    tag: "Computers & Design Software",
    details:
      "Bring your creative visions to life by using our computers, all equipped with a full suite of professional editing and design software. Whether you're editing videos, designing graphics, or diving into development work, our powerful computers have you covered.",
    popup: (
      <>
        What we offer:
        <ul>
          <li>
            <strong>Professional Suite: </strong> Access a complete suite of
            industry-standard editing and design software that empowers you to
            bring your projects to life with precision and finesse.
          </li>
          <li>
            <strong>Video Editing: </strong>
            Seamlessly edit videos, add effects, and fine-tune audio to create
            captivating visual stories that leave a lasting impact.
          </li>
          <li>
            <strong>Graphic Design: </strong>
            Dive into graphic design projects with the confidence that our
            computers can handle intricate illustrations, layouts, and photo
            enhancements.
          </li>
          <li>
            <strong>Development Opportunities: </strong>
            For those with a passion for coding and development, our computers
            provide the horsepower you need to bring digital dreams to life.
          </li>
          <li>
            <strong>3D Modeling: </strong>
            Explore the world of 3D modeling and bring your imagination to life
            by crafting intricate three-dimensional designs and creations.
          </li>
        </ul>
        Be sure to browse through our class schedule to discover a range of
        informative workshops focused on harnessing the potential of advanced
        software available at Creator Zone.
      </>
    ),
  },

  {
    picture: ScreenPrinting, // Service1Image,
    title: "Sublimation Printing and Heat Press",
    tag: "Sublimation Printing and Heat Press",
    details:
      "Dive into the world of Sublimation Printing and Heat Press at Creator Zone, where your creative ideas come to life in vibrant color and detail. Our equipment allows you to transfer stunning, high-resolution designs onto a variety of surfaces, from apparel and accessories to mugs and more. With this technology at your fingertips, you can craft personalized gifts, branded merchandise, and unique keepsakes like never before.",
    popup: (
      <>
        What's in Store:
        <ul>
          <li>
            <strong>Vibrant Personalization: </strong>
            Transform everyday items into personalized masterpieces with
            full-color, high-quality designs.
          </li>
          <li>
            <strong>Professional Results: </strong>Our heat press ensures
            professional-grade results, with crisp and lasting prints that stand
            out.
          </li>
          <li>
            <strong>Guided Workshops: </strong> Learn the ins and outs of
            sublimation printing through our guided workshops, allowing you to
            become confident with sublimation!
          </li>
        </ul>
      </>
    ),
  },
  {
    picture: PaintingAndDrawing,
    title: "Painting & Drawing",
    tag: "Painting & Drawing",
    details:
      "Discover our Painting & Drawing Studio, a true sanctuary for artistic expression. Whether you're a seasoned artist or an emerging creator, our studio is thoughtfully stocked with paints, brushes, pens, pencils, paper, and canvases—all of which are included for use with your membership.",
    popup: (
      <>
        <ul>
          <li>
            <strong>Artistic Supplies: </strong>
            Explore a wide array of art materials, from paints to brushes, pens,
            pencils, paper, and canvases, providing you with ample resources to
            fuel your creativity.
          </li>
          <li>
            <strong>Creative Freedom: </strong>
            Embrace the liberty to experiment with various mediums and
            techniques, allowing your artistic journey to evolve naturally.
          </li>
          <li>
            <strong> Inspiring Ambiance: </strong>
            Immerse yourself in a well-lit and creatively adorned space,
            designed to cultivate an atmosphere where your creative spirit can
            flourish.
          </li>
          <li>
            <strong>Guided Learning: </strong>
            Participate in art classes and workshops led by talented
            instructors, ready to assist you in mastering various techniques and
            honing your skills.
          </li>
          <li>
            <strong>Flexible Studio Time: </strong>
            Enjoy the convenience of accessing the arts studio during all open
            hours, enabling you to work at your own pace and schedule, whenever
            inspiration strikes.
          </li>
          <li>
            <strong>Artistic Community: </strong>
            Connect with fellow artists, fostering a sense of camaraderie, and
            engage in collaborative projects that inspire and elevate your work.
          </li>
          <li>
            <strong>Inclusive Atmosphere: </strong>
            Whether you're a beginner seeking guidance or a seasoned artist
            looking for a space to explore, our studio offers an inclusive and
            supportive environment for artists of all backgrounds and expertise
            levels.
          </li>
        </ul>
      </>
    ),
  },
  {
    picture: CollabSpace,
    title: "Co-Working & Collaborative spaces",
    tag: "Co-Working & Collaborative spaces",
    details:
      "Our Shared Community Tables are more than just a place to work; they're the epicenter of collaboration, where the power of collective creativity is unleashed. Here, individuals from diverse backgrounds and disciplines converge, driven by a common purpose: to bring innovative ideas and projects to fruition through the magic of collaboration.",
    popup: (
      <>
        <ul>
          <li>
            <strong>Diverse Perspectives: </strong>
            Our shared tables are a melting pot of diverse talents and
            expertise, sparking innovative thinking. Here, artists mingle with
            engineers, writers collaborate with designers, and dreamers unite to
            shape the future.
          </li>
          <li>
            <strong>Positive Energy: </strong>
            There's a unique energy when kindred spirits gather to tackle
            challenges, explore uncharted territories, and craft remarkable
            creations. It's a magnetic force that propels projects forward,
            driven by shared enthusiasm.
          </li>
          <li>
            <strong>Supportive Community: </strong>
            Beyond the tables, you'll discover a supportive ecosystem where
            constructive feedback, mentorship, and a genuine desire for each
            other's success are the norm. It's a place where everyone thrives
            together.
          </li>
          <li>
            <strong>Inspirational Setting: </strong>
            Our surroundings are carefully designed to inspire. Surrounded by
            accessible art supplies, sewing machines, printers, and cozy nooks,
            your collaborative spirit finds the nourishment it needs, and your
            ideas find fertile ground.
          </li>
        </ul>
      </>
    ),
  },
  {
    picture: Podcast,
    title: "Audio & Video Recording",
    tag: "Audio & Video Recording",
    details:
      "Unleash your inner podcaster, or vocalist in our fitted out recording studio. These soundproof sanctuaries are equipped with audio and video equipment, ensuring your creative vision comes to life with precision and clarity.",
    popup: (
      <>
        <ul>
          <li>
            <strong>Soundproof Sanctuary: </strong> Enjoy a quiet and
            distraction-free environment where your creative ideas can flow
            without interruption.
          </li>
          <li>
            <strong> Modern Equipment: </strong>
            Access top-of-the-line audio and video equipment that ensures your
            recordings are of the highest quality.
          </li>
          <li>
            <strong>Clarity: </strong>
            Experience the power of precise and crystal-clear sound, allowing
            your creative endeavors to shine.
          </li>
          <li>
            <strong> Guidance and Expertise: </strong>
            Benefit from our workshops and expert guidance to make the most of
            our recording studios, whether you're a seasoned pro or just
            starting out.
          </li>
        </ul>
      </>
    ),
  },
  {
    picture: LaserEngraving,
    title: "Laser Engraving & Cutting",
    tag: "Laser Engraving & Cutting",
    details:
      "Utilize our Laser Engravers to experience a realm where precision and creativity converge to transform ordinary materials into extraordinary works of art. Explore the endless possibilities of laser technology and unleash your innovative spirit.",
    popup: (
      <>
        What Awaits You:
        <ul>
          <li>
            <strong>Precision Craftsmanship: </strong>
            Achieve intricate and precise designs on various materials,
            elevating them into unique pieces of art with unparalleled accuracy.
          </li>
          <li>
            <strong>Explore the possibilities: </strong>
            Craft personalized gifts, custom signage, and decorative items! Set
            your innovative spirit free as you experiment with different
            materials and techniques, allowing your creative visions to shine
            through.
          </li>
          <li>
            <strong>Expert Guidance: </strong>
            Join our workshops and receive expert guidance to master the art of
            laser engraving, ensuring your projects reach their full potential.
          </li>
        </ul>
      </>
    ),
  },
  {
    picture: WoodWorking, // Service7Image,
    title: "Woodwork",
    tag: "Woodwork",
    details:
      "Step into the world of craftsmanship and creativity at Creator Zone's Woodshop, where the art of woodworking comes to life. Our Woodshop (Thanks to Ace Hardware Lake Stevens!) is a haven for woodworking enthusiasts, offering a range of tools and resources to turn your woodworking projects into tangible works of art.",
    popup: (
      <>
        What You Can Expect:
        <ul>
          <li>
            <strong>Equipment: </strong>
            Access a wide array of woodworking tools and machinery
          </li>
          <li>
            <strong>Endless Possibilities: </strong>
            Dive into an expansive world of possibilities, from crafting
            furniture and intricate woodwork to personalized home decor and
            more.
          </li>
          <li>
            <strong>Expert Guidance: </strong>
            Join our woodworking workshops and receive guidance from
            experienced craftsmen, ensuring your projects are not only creative
            but also safe and well-executed.
          </li>
          <li>
            <strong>Collaborative Environment:</strong>
            Connect with fellow woodworkers, share ideas, and collaborate on
            projects in a supportive and inspiring atmosphere.
          </li>
          <li>
            <strong>Safety First: </strong>
            We prioritize safety in our Woodshop, providing the necessary
            training and safety measures to ensure a secure and enjoyable
            woodworking experience.
          </li>
        </ul>
      </>
    ),
  },

  {
    picture: Circuit, // Service7Image,
    title: "Cricut",
    tag: "Cricut",
    details:
      "Elevate your crafting and design projects to new heights with the Cricut machine at Creator Zone. This versatile and cutting-edge tool empowers you to transform your creative visions into reality with precision and flair. Whether you're a DIY enthusiast, a seasoned crafter, or a graphic designer, the Cricut Machine opens up a world of possibilities for personalized and professional-quality creations.",
    popup: (
      <>
        What Awaits You:
        <ul>
          <li>
            <strong>Precision Cutting: </strong>
            Harness the power of precision cutting to create intricate designs,
            custom apparel, unique home decor, and more with ease.
          </li>
          <li>
            <strong>Versatile Materials: </strong>
            From paper and vinyl to fabric and leather, the Cricut Machine
            handles a wide range of materials, allowing you to experiment and
            explore.
          </li>
          <li>
            <strong>Design Freedom: </strong>
            Bring your unique ideas to life by designing your own projects or
            selecting from a vast library of pre-made designs and templates.
          </li>
          <li>
            <strong>User-Friendly: </strong>
            The Cricut Machine is user-friendly and suitable for crafters of all
            levels, ensuring an enjoyable and seamless creative process.
          </li>
          <li>
            <strong>Inspiration and Innovation: </strong>
            Join our workshops and crafting community to share ideas, learn new
            techniques, and stay inspired on your crafting journey.
          </li>
        </ul>
      </>
    ),
  },
];

const SignUpCard = () => {
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const res = await postUserInfo({ email });
      setIsLoading(false);
      if (res?.ok) {
        setEmail("");
      }
    } catch (err) {
      console.log("err", err);
      setIsLoading(false);
    }
  };
  return (
    <form onSubmit={handleSubmit} className="servicesSec_signUpCard">
      <h3>Get the latest updates for our services</h3>
      <input
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        type="email"
        placeholder="Add your email address"
        required
      />
      <Button disabled={isLoading} type="submit" white>
        {isLoading ? "Signing up..." : "Sign me up"}
      </Button>
    </form>
  );
};

const MobileServicesCards = forwardRef(
  ({ setActiveSlide, handleServiceClick }, ref) => {
    const settings = {
      className: "slider",
      dots: true,
      infinite: false,
      slidesToShow: 2.2,
      slidesToScroll: 2,
      initialSlide: 2,
      arrows: false,
      afterChange: (next) => {
        setActiveSlide(
          next > ServicesData.length - 2
            ? ServicesData.length - 1
            : parseInt(next)
        );
      },

      responsive: [
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1.7,
            slidesToScroll: 1,
            initialSlide: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1.3,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <div className="mobileServicesCarousel">
        <Slider ref={ref} {...settings}>
          {ServicesData.map((d, i) => (
            <div
              key={i}
              onClick={() => handleServiceClick(i)}
              className="servicesSec_service"
            >
              <div>
                <img src={d.picture} alt="service" loading="lazy" />
                <div>
                  <h3>{d.title}</h3>
                  <p>{d.details}</p>
                </div>
              </div>
              <img
                className="servicesSec_serviceArrow"
                src={LongRightArrow}
                alt="arrow"
              />
            </div>
          ))}
        </Slider>
        {/* <SignUpCard /> */}
      </div>
    );
  }
);

const Tags = ({ activeSlide, handleTagClick }) => {
  return (
    <div className="servicesSec_tags">
      {ServicesData.map((d, i) => (
        <span
          key={i}
          className={`${activeSlide === i ? "servicesSec_tagActive" : ""}`}
          onClick={() => handleTagClick(i)}
        >
          {d.tag}
        </span>
      ))}
    </div>
  );
};

const ServicesSec = () => {
  const sliderRef = useRef();

  const [servicesModal, setServicesModal] = useState(false);
  const [currentServiceModal, setCurrentServiceModal] = useState(0);

  const [activeSlide, setActiveSlide] = useState(0);

  const handleTagClick = (index) => {
    sliderRef.current.slickGoTo(index);
    setActiveSlide(index);
  };

  const handleServiceClick = (index) => {
    setCurrentServiceModal(index);
    setServicesModal(true);
  };

  return (
    <>
      <ServicesPopup
        data={ServicesData}
        isOpen={servicesModal}
        setIsOpen={setServicesModal}
        currentServiceModalIndex={currentServiceModal}
        setCurrentServiceModalIndex={setCurrentServiceModal}
      />
      <section id="services" className="servicesSec">
        <div className="servicesSec_images">
          <img src={ServiceBlue1} alt="blue1" className="servicesSec_blue1" />
          <img src={ServiceBlue2} alt="blue2" className="servicesSec_blue2" />
          <img src={ServiceBlue3} alt="blue3" className="servicesSec_blue3" />
          <img
            src={ServiceBlueLine}
            alt="blue3"
            className="servicesSec_blueLine"
          />
        </div>
        <ServicesCarousel />
        <div className="servicesSec_titleContainer">
          <div>
            <small>
              What’s a part of my membership? Full access to ALL of the below!
            </small>
            <h3>Maker Labs & Resources</h3>
          </div>
          <p>
            Creator Zone offers a dynamic array of cutting-edge maker labs to
            fuel your creativity and innovation. From soundproof recording
            studios for podcasts, videos, and music to 3D printing and laser
            cutting machines, we provide the tools to bring your ideas to life.
            Immerse yourself in our VR Lab, capture the perfect shot in our
            Photography Studio, and explore robotics in our workshop.
            Woodworking enthusiasts will find premium tools in our Woodwork
            Room, and our community space is a hub for inspiration and
            collaboration. Plus, we cater to crafters with sewing stations,
            sergers, and an extensive collection of arts and craft machines,
            including sublimation printers, presses, and Cricut. We offer
            introductory classes to help you get started. Join us at Creator
            Zone for limitless creativity and exploration. 🌟🎥🖨️🛠️🪡🎨
          </p>
        </div>
        <Tags
          activeSlide={activeSlide}
          handleTagClick={handleTagClick}
          setActiveSlide={setActiveSlide}
        />
        <div className="servicesSec_services">
          {ServicesData.map((d, i) => (
            <div
              key={i}
              onClick={() => handleServiceClick(i)}
              className="servicesSec_service"
            >
              <div>
                <img src={d.picture} alt="service" loading="lazy" />
                <h3>{d.title}</h3>
                <p>{d.details}</p>
              </div>
              <img
                className="servicesSec_serviceArrow"
                src={LongRightArrow}
                alt="arrow"
              />
            </div>
          ))}
          {/* <SignUpCard /> */}
        </div>
        <MobileServicesCards
          handleServiceClick={handleServiceClick}
          setActiveSlide={setActiveSlide}
          ref={sliderRef}
        />
      </section>
    </>
  );
};

export default ServicesSec;
