import React, { forwardRef } from "react";
import "./Textarea.scss";

const Textarea = forwardRef(({ id, label, type = "text", ...rest }, ref) => {
  return (
    <div className="textarea">
      {label && <label htmlFor={id}>{label}</label>}
      <input ref={ref} id={id} type={type} {...rest} />
      <span  >{ref.current?.value?.length || 0}/300</span>
    </div>
  );
});

export default Textarea;
